import { createContext, useMemo, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import getThemePalette from 'theme/ThemePalette';

const ModeContext = createContext({ toggleMode: () => {} });

const MODE = {
  light: 'light',
  dark: 'dark',
};

const ModeProvider = ({ children }) => {
  const localeMode = localStorage.getItem('mode');
  const [modeState, setModeState] = useState(localeMode || MODE.light);

  const mode = useMemo(
    () => ({
      toggleMode: () => {
        setModeState((prevMode) =>
          prevMode === MODE.light ? MODE.dark : MODE.light,
        );
      },
    }),
    [],
  );

  const theme = useMemo(() => {
    localStorage.setItem('mode', modeState);
    return createTheme(getThemePalette(modeState));
  }, [modeState]);

  return (
    <ModeContext.Provider value={mode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ModeContext.Provider>
  );
};

export { MODE, ModeContext };
export default ModeProvider;
